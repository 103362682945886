var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',_vm._b({staticClass:"shadow-sm",attrs:{"color":_vm.$vuetify.theme.dark
            ? 'dark'
            : _vm.getThemeMode.verticalSidebarDrawerColor,"expand-on-hover":_vm.getThemeMode.verticalSidebarMini,"mini-variant":_vm.getThemeMode.verticalSidebarMini,"src":_vm.bg,"dark":_vm.getThemeMode.verticalSidebarDrawerColor != 'white' ? true : false,"app":"","disable-resize-watcher":false,"mobile-breakpoint":960,"height":"100%","floating":true,"right":_vm.$vuetify.rtl},on:{"update:expandOnHover":function($event){return _vm.$set(_vm.getThemeMode, "verticalSidebarMini", $event)},"update:expand-on-hover":function($event){return _vm.$set(_vm.getThemeMode, "verticalSidebarMini", $event)}},scopedSlots:_vm._u([{key:"img",fn:function(props){return [_c('v-img',_vm._b({staticClass:"test",style:({opacity: _vm.opacity})},'v-img',props,false))]}}]),model:{value:(_vm.getThemeMode.verticalSidebarDrawer),callback:function ($$v) {_vm.$set(_vm.getThemeMode, "verticalSidebarDrawer", $$v)},expression:"getThemeMode.verticalSidebarDrawer"}},'v-navigation-drawer',_vm.$attrs,false),[_c('vue-perfect-scrollbar',{staticClass:"h-100 rtl-ps-none ps scroll",staticStyle:{"height":"100%"},attrs:{"settings":{suppressScrollX: true, wheelPropagation: false}}},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-avatar'),_c('v-list-item-title',{staticClass:"text-18 text-uppercase text-default"},[_c('img',{attrs:{"src":"/form_logo.png"}})]),_c('v-scroll-x-transition',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSidebar($event)}}},[(!_vm.getThemeMode.verticalSidebarMini)?_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":"","color":""}},'v-icon',attrs,false),on),[_vm._v("mdi-circle-slice-8")])]}}],null,false,1300895161)},[_c('span',[_vm._v("UnPin")])]):_vm._e(),(_vm.getThemeMode.verticalSidebarMini)?_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":"","color":""}},'v-icon',attrs,false),on),[_vm._v("mdi-circle-outline")])]}}],null,false,3279625276)},[_c('span',[_vm._v("pin")])]):_vm._e()],1)],1)],1)],1),_c('v-list',[_vm._l((_vm.computedItems),function(item,i){return [(item.children)?_c('base-item-group',{key:("group-" + i),attrs:{"item":item}}):_c('base-item',{key:("item-" + i),attrs:{"item":item}})]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }